<template>
  <v-container fluid>
    <v-row no-gutters align='center' justify='center'>
      <div class='text-h5'>
        {{$t('PERMISSION_GROUPS')}}
      </div>

      <v-btn icon class='ml-3 mt-1' @click='getData'>
        <v-icon>mdi-refresh</v-icon>
      </v-btn>

      <v-spacer />

      <v-btn text @click='detailsDialog = true; isEditing = false'>
        <v-icon class='mr-2'>
          mdi-account-plus-outline
        </v-icon>
        {{$t('NEW_PERMISSION_GROUP')}}
      </v-btn>
    </v-row>

    <v-divider class='my-3' />

    <v-row no-gutters>
      <v-card flat class='wd-100'>
        <v-card-title class='py-4'>
          <v-spacer />

          <v-text-field
            v-model='search'
            append-icon='mdi-magnify'
            :label='$t("SEARCH")'
            single-line
            hide-details
            clearable
          />
        </v-card-title>

        <v-data-table
          class='wd-100'
          multi-sort
          :search='search'
          :headers='permissionGroupTableHeaders'
          :items='list'
          :footer-props='{
            itemsPerPageOptions: [50, 100, 300, -1]
          }'
        >
          <template #item.name='{ item }'>
            <v-list-item class='pa-0'>
              <v-list-item-content>
                <v-list-item-title>
                  {{item.name}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template #item.customer='{ item }'>
            <v-list-item class='pa-0'>
              <v-list-item-content>
                <v-list-item-title>
                  {{item.customer}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template #item.createdAt='{ item }'>
            <v-tooltip top>
              <template #activator='{ on }'>
                <div v-on='on'>
                  {{formattedDate(item.createdAt)}}
                </div>
              </template>
              {{formattedDateInWords(item.createdAt)}}
            </v-tooltip>
          </template>

          <template #item.actions='{ item }'>
            <v-menu
              bottom
              left
            >
              <template #activator='{ on, attrs }'>
                <v-btn
                  icon
                  v-bind='attrs'
                  v-on='on'
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list class='pa-0'>
                <v-list-item @click='handleOnClickEdit(item.id)'>
                  <v-list-item-icon class='mr-3'>
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{$t('EDIT_REGISTER')}}</v-list-item-title>
                </v-list-item>
                <v-list-item @click='handleOnClickDelete(item.id)'>
                  <v-list-item-icon class='mr-3'>
                    <v-icon>mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{$t('DELETE_REGISTER')}}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </v-row>

    <!-- ---------------------------------------------------------------- -->
    <!-- DIALOGS -->
    <!-- ---------------------------------------------------------------- -->
    <v-dialog v-model='detailsDialog' fullscreen persistent>
      <v-card v-if='detailsDialog' style='background-color: var(--v-background-base);'>
        <v-container fluid class='pa-0'>
          <v-app-bar dark color='primary'>
            <v-tooltip bottom>
              <template #activator='{ on }'>
                <v-btn icon v-on='on' @click.native='detailsDialog = false'>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>{{$t('CLOSE')}}</span>
            </v-tooltip>

            <div class='text-h5 ml-2'>
              {{$t('PERMISSION_GROUP')}}
            </div>
          </v-app-bar>

          <permission-group-details :edit='isEditing' :permission-group-id='permissionGroupId' @new-permission-group='getData' @edit-permission-group='handleOnConfirmEdit' @close='detailsDialog = false' />
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import ptBr from 'date-fns/locale/pt-BR';
  import Swal from 'sweetalert2';

  import { mapGetters } from 'vuex';
  import { format, parseISO, formatDistanceToNow } from 'date-fns';

  export default {
    name: 'PermissionGroups',
    components: {
      // eslint-disable-next-line vue/no-unused-components
      PermissionGroupDetails: () => import('./PermissionGroupDetails'),
    },
    computed: {
      ...mapGetters('auth', ['getUser']),
    },
    data: function () {
      return {
        list: undefined,
        search: undefined,
        permissionGroupTableHeaders: [
          { text: this.$t('NAME'), value: 'name' },
          { text: this.$t('CREATION_DATE'), value: 'createdAt' },
          {
            text: '',
            sortable: false,
            value: 'actions',
            width: '30',
            align: 'center',
          },
        ],
        permissionGroupId: 0,
        isEditing: false,
        detailsDialog: false,
        menuDialog: false,
        permissionDialog: false,
        permissionGroupAccessDataDialog: false,
        selectedPermissionGroup: undefined,
      };
    },
    mounted: function () {
      if (this.getUser.account.role.isSuperAdmin) {
        this.permissionGroupTableHeaders.splice(1, 0, { text: this.$t('CUSTOMER'), value: 'customer' });
      }
      this.getData();
    },
    methods: {
      handleOnClickEdit: function (id) {
        this.permissionGroupId = id;
        this.isEditing = true;
        this.detailsDialog = true;
      },
      handleOnClickDelete: function (id) {
        Swal.fire({
          icon: 'warning',
          title: this.$t('WARNING'),
          text: this.$t('PERMISSION_GROUP_DELETE_MESSAGE'),
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: this.$t('CANCEL'),
          confirmButtonText: this.$t('CONFIRM'),
        }).then(async (result) => {
          if (result.value) {
            await this.deletePermissionGroupById(id);
            await this.getData();
          }
        });
      },
      handleOnConfirmEdit: function () {
        this.permissionGroupId = 0;
        this.isEditing = false;
        this.getData();
      },
      openPermissionGroupAccessDataDialog: function (item) {
        this.selectedPermissionGroup = item;
        this.permissionGroupAccessDataDialog = true;
      },
      closePermissionGroupAccessDataDialog: function () {
        this.permissionGroupAccessDataDialog = false;
        this.selectedPermissionGroup = undefined;
      },
      formattedDate: function (value) {
        if (!value) return '';
        return format(parseISO(value), 'dd/MM/yyyy HH:mm');
      },
      formattedDateInWords: function (value) {
        if (!value) return '';
        return formatDistanceToNow(parseISO(value), { locale: ptBr });
      },
      deletePermissionGroupById: async function (id) {
        try {
          await axios({
            url: `/permission-group/${id}`,
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'DELETE',
            responseType: 'json',
            withCredentials: true,
          });
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      getData: async function () {
        try {
          const { data } = await axios({
            url: `/permission-group/${this.getUser.account.customerId}`,
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });

          this.list = data && data.list;
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
    },
  };
</script>
